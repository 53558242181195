import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PortfolioItemSummary from "../../components/PortfolioItemSummary"
import LogoCSU from "../../images/logo-csu-inverted.svg"
import CourseSpaceIllustration from "../../images/illustration-smart-tools.svg"

const PortfolioCSU = () => {
  return (
    <Layout fgMode="light" bgClass="bg-black">
      <Seo 
        title="Charles Sturt University"
        description="A visionary approach to designing course ware"
      />
      <section className="mx-auto text-center text-white bg-black">
        <div className="flex flex-col items-center mx-auto lg:max-w-screen-lg">
          <LogoCSU className="mt-2" height={80}/>
          <h1 className="mx-2 mt-8 text-4xl font-semibold">Charles Sturt University</h1>
          <p className="mt-2 text-2xl text-center">A visionary approach to designing course ware.</p>
        </div>
        <PortfolioItemSummary 
            expertise="UX and Development" 
            platforms="Web" 
            deliverables="API, Frontend" 
            href="https://www.csu.edu.au/division/learning-and-teaching/home/technologies-for-learning-and-teaching/coursespace" 
            linkText="CourseSpace" />          
      </section>
      <div className="lg:grid grid-cols-[1fr,800px,1fr] grid-rows-[repeat(7,1fr)] hidden">
        <div className="flex col-start-1 col-end-4 row-start-1 row-end-3 bg-black">
        </div>
        <div className="col-start-2 col-end-3 row-start-1 row-end-7">
          <div className="shadow-2xl">
            <CourseSpaceIllustration width={800} />
          </div>
          <p className="mt-10 text-sm text-center text-gray-400">Screenshot of the live interface cannot be displayed, due to permissions.</p>
        </div>
      </div>

      <div className="mx-4 mt-10 text-gray-700 lg:mx-auto lg:max-w-screen-md lg:mt-0">
        <p className="mb-6 text-2xl">
          Charles Sturt University’s character is true to regional Australia - with gumption, soul and a collaborative nature. 
        </p>
        <p className="mb-6 text-2xl">
          CSU develops holistic, far-sighted people who help their communities grow and flourish.
        </p>
        <p className="mb-6 text-2xl">
          The Wiradjuri phrase <i>yindyamarra winhanganha</i> means the wisdom of respectfully knowing how to live well in a world worth living in.
        </p>
        <p className="mb-6 text-2xl">
          This phrase is the ethos of CSU  and speaks to the mission of universities &mdash; to develop and spread wisdom to make the world a better place.
        </p>
      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-anomaly-red my-14">Digitising course design</h2>
        <p className="mb-6 text-xl">
          CourseSpace is a collaborative online environment that supports the Course Design Process at CSU.
        </p>
        <p className="mb-6 text-xl">
          Using CourseSpace, a course team can collaborate to develop a course, evaluate it and submit it for 
          internal and external accreditation. The software consists of design modules that enable a course team to 
          frame a course, map standards, build authentic course outcomes and build assessment tasks and subjects 
          within a fully integrated, mapped, and scaffolded design approach. 
        </p>
        <p className="mb-6 text-xl">
          Feedback can be generated at all stages of the design and implementation process, for all levels of use, 
          making "just in time" course correction possible.
        </p>
        <p className="mb-6 text-xl">
          When the course design is complete, CourseSpace can be used to prepare an accreditation submission, 
          and accreditors can review the course online in the software.
        </p>
        <a href="https://www.csu.edu.au/division/learning-and-teaching/home/technologies-for-learning-and-teaching/coursespace" className="flex items-center justify-center mt-10 text-lg">
          Visit CSU's CourseSpace page
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </a>

      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-anomaly-red my-14">Who we worked with</h2>
        <p className="mb-6 text-xl">
          We worked with the university’s information technology department to meet their technology requirements. 
          It was a great partnership, with us developing and them delivering the product to the academic staff.        
        </p>
      </div>

    </Layout>
 );
};
export default PortfolioCSU
